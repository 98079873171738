import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";

import { OverviewMapPage } from "./OverviewMapPage";
import { PrivacyPage } from "./PrivacyPage";
import { Header } from "./components/Header";
import { AlertPopup } from "./components/AlertPopup";
import { OfferMap } from "./components/OfferMap";
import AuthCallback from "./AuthCallback";
import ErrorPage from "./components/ErrorPage";
import {
  TenantConfig,
  TenantMapProvider,
  useTenantMap,
} from "./components/TenantMapContext";
import SuccessPurchase from "./components/SuccessPurchase";
import PurchaseError from "./components/PurchaseError";
import MyMapsList from "./components/MyMapsList";
import { useUser } from "./components/UserContext";
import { TermsAndConditionsPage } from "./TermsAndConditionsPage";
import usePageTracking from "./usePageTracking";

// Configuration object as discussed
const tenantMapConfigs: TenantConfig = {
  djemographic: {
    topvillages: {
      tenant: "djemographic",
      map: "topvillages",
      site: "app.pioneermaps.com",
      creator: "@djemographic",
      discountedPrice: "CHF 19.90",
      priceID: process.env.REACT_APP_DJEMOGRAPHIC_TOPVILLAGES_PRICE_ID,
      title: "Top Switzerland Guide",
      description:
        "Switzerland's Highlights and Secrets Unveiled: This interactive map features my YouTube videos and Instagram spots, guiding you to less-known, non-touristy places around popular destinations. Unveil the real beauty of Switzerland and explore like a local!",
      imagePath:
        process.env.REACT_APP_STATIC_RESOURCES +
        "/offer_map_djemographic_top_villages_300x593.gif",
    },
  },
  epicswiss: {
    switzerlandtravelguide: {
      tenant: "epicswiss",
      map: "switzerlandtravelguide",
      site: "app.pioneermaps.com",
      creator: "@epic.swiss",
      price: "$19.95",
      discountedPrice: "$14.95",
      priceID: "price_1OqIA6A6Vp6D2NcvAAqYvSyx",
      title: "Switzerland Travel Guide",
      description:
        "Unlock Switzerland’s Best-Kept Secrets: Explore hidden gems and must-visits that even locals haven’t discovered. Make the best of your priceless time!",
      imagePath:
        process.env.REACT_APP_STATIC_RESOURCES +
        "/offer_map_epicswiss_switzerlandtravelguide.png",
    },
  },
  visitswitzerland: {
    app: {
      tenant: "visitswitzerland",
      map: "app",
      site: "app.pioneermaps.com",
      creator: "@robi_crusoe",
      price: "CHF 24.90",
      discountedPrice: "CHF 19.90",
      priceID: process.env.REACT_APP_ROBI_CRUSOE_VISIT_SWITZERLAND_MAP_PRICE_ID,
      title: "Visit Switzerland Map",
      description:
        "The Visit Switzerland Map brings together the most beautiful and authentic pictures and video scenes across media platforms, locates them on a first class Swiss map and provides helpful infos for interested visitors and Switzerland lovers.",
      imagePath:
        process.env.REACT_APP_STATIC_RESOURCES +
        "/offer_map_robi_crusoe_demo_map_300x593.gif",
    },
  },
  robi_crusoe: {
    demo: {
      tenant: "robi_crusoe",
      map: "demo",
      site: "app.pioneermaps.com",
      creator: "@robi_crusoe",
      price: "CHF 24.90",
      discountedPrice: "CHF 19.90",
      priceID: process.env.REACT_APP_ROBI_CRUSOE_VISIT_SWITZERLAND_MAP_PRICE_ID,
      title: "Badass Demo Map",
      description:
        "This map shows how badass Pioneer Maps can be. It's a demo map, so don't expect too much. But it's still pretty cool.",
      imagePath:
        process.env.REACT_APP_STATIC_RESOURCES +
        "/offer_map_robi_crusoe_demo_map_300x593.gif",
    },
  },
  michelphotographych: {
    "secret-gems-of-switzerland": {
      tenant: "michelphotographych",
      map: "secret-gems-of-switzerland",
      site: "app.pioneermaps.com",
      creator: "@michelphotographych",
      discountedPrice: "CHF 20.00",
      priceID:
        process.env
          .REACT_APP_MICHELPHOTOGRAPHYCH_SECRET_GEMS_OF_SWITZERLAND_PRICE_ID,
      title: "Secret Gems of Switzerland",
      description:
        "My favorite spots in Switzerland - in one map. This unique map gives you access to many of my favourite places in Switzerland. It is constantly being expanded and you will receive lots of valuable information about the individual spots.",
      imagePath:
        process.env.REACT_APP_STATIC_RESOURCES +
        "/offer_map_michelphotographych_secret-gems-of-switzerland_300x593.gif",
    },
  },
};

interface DynamicOfferRouteProps {}

const DynamicOfferRoute: React.FC<DynamicOfferRouteProps> = ({}) => {
  let { tenant, map } = useParams<{ tenant: string; map: string }>();
  const { updateTenantMap } = useTenantMap();
  const currentConfig = tenantMapConfigs[tenant]?.[map];
  const { isAuthenticated } = useAuth0();
  const { profile } = useUser();

  useEffect(() => {
    if (currentConfig) {
      updateTenantMap(currentConfig);
    }
    // Ensure to clear the config when the component unmounts or tenant/map changes
    return () => {
      updateTenantMap(undefined);
    };
  }, [currentConfig, updateTenantMap]);

  const userHasAccessToMap = useMemo(() => {
    return (
      profile?.mymaps?.some(
        (mapConfig) => mapConfig.tenant === tenant && mapConfig.map === map
      ) || false
    );
  }, [profile, tenant, map]);

  if (!currentConfig) {
    return (
      <div style={{ paddingTop: "66px", paddingBottom: "10px" }}>
        <ErrorPage />
      </div>
    );
  }

  return isAuthenticated && userHasAccessToMap ? (
    <OverviewMapPage
      mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      gygPartnerID={process.env.REACT_APP_GYG_PARTNER_ID}
    />
  ) : (
    <div style={{ paddingTop: "66px", paddingBottom: "10px" }}>
      <OfferMap
        title={currentConfig.title}
        creator={currentConfig.creator}
        tenantName={currentConfig.tenant}
        mapName={currentConfig.map}
        priceID={currentConfig.priceID}
        originalPrice={currentConfig.price}
        discountedPrice={currentConfig.discountedPrice}
        description={currentConfig.description}
        imagePath={currentConfig.imagePath}
      />
    </div>
  );
};

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null; // Render nothing as we're performing a full page reload
};

interface AppStateProps {
  history: History;
}

const App: React.FC<AppStateProps> = ({ history }) => {
  const { isLoading } = useAuth0();
  const { profile } = useUser();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <TenantMapProvider>
      <ConnectedRouter history={history}>
        <PageTracker />
        <Helmet>{/* Dynamic Helmet setup can be done here if needed */}</Helmet>
        <Header
          logoClickURL={window.location.origin + window.location.pathname}
          contactFormURL={process.env.REACT_APP_CONTACT_FORM}
        />
        <div
          style={{
            position: "absolute",
            top: 70,
            left: 0,
            right: 0,
            zIndex: 1600,
          }}
        >
          <AlertPopup />
        </div>
        <Switch>
          <Route exact path="/">
            <ExternalRedirect to={process.env.REACT_APP_HOME_REDIRECT_URL} />
          </Route>
          <Route path="/auth-callback" component={AuthCallback} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/terms" component={TermsAndConditionsPage} />
          <Route
            path="/checkout/success/:tenant_name/:map_name/:user_id"
            render={(props) => (
              <SuccessPurchase
                {...props}
                style={{ paddingTop: "96px", marginTop: "66px" }}
              />
            )}
          />
          <Route
            path="/checkout/cancel/:tenant_name/:map_name/:user_id"
            render={(props) => (
              <PurchaseError
                {...props}
                style={{ paddingTop: "96px", marginTop: "10px" }}
              />
            )}
          />
          <Route
            path="/mymaps"
            render={() => (
              <MyMapsList
                configs={profile?.mymaps || []}
                style={{ paddingTop: "66px", marginTop: "10px" }}
              />
            )}
          />
          <Route path="/:tenant/:map">
            <DynamicOfferRoute />
          </Route>
          <Route
            path="*"
            render={() => (
              <div style={{ paddingTop: "66px", paddingBottom: "10px" }}>
                <ErrorPage />
              </div>
            )}
          />
        </Switch>
      </ConnectedRouter>
    </TenantMapProvider>
  );
};

// PageTracker is a helper component to add the usePageTracking hook
// Because the usePageTracking hook needs to be called somehere within the ConnectedRouter component
const PageTracker = () => {
  usePageTracking();
  return null;
};

// Connect the App component to Redux store if needed
export default connect()(App);
