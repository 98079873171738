import React, { useState, useRef, useEffect } from "react";
import ReactMapGl, {
  GeolocateControl,
  Layer,
  LayerProps,
  Source,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import IconButton from "./IconButton";
import "./MapVisitSwitzerland.css";
import FilterIconSVG from "../FilterIcon.svg";
import { IMarkerState } from "../OverviewMapPage";
import history from "../redux/history";
import { connect } from "react-redux";
import { FilterForm } from "./FilterForm";
import { IGetVideoResponse, IScene } from "@visitswitzerland/common/src";
import { sendEvent } from "../analytics/events";
import { useAlert } from "./AlertContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useTenantMap } from "./TenantMapContext";
import { useApiService } from "@visitswitzerland/common";
import { IGetPhotoResponse } from "@visitswitzerland/common/dist/types";
import { LabelSeason } from "./LabelSeason";
import { LabelPlatform } from "./LabelPlatform";
import { LabelType } from "./LabelType";
import { PlatformIcons, SeasonIcons, TypeIcons } from "./CustomIcons";
import { FilterButton } from "./FilterButton";
import { start } from "repl";

// generate a filter expression for the mapbox layer based on the filter state
function generateFilterExpr(
  layer: string,
  categories: string[],
  activeLabel: string,
  activeMarkerPlatform?: string,
  activeMarkerID?: string,
  activeMarkerStartTime?: number
) {
  const allFilterExpr: any[] = [];

  // Remove clusters if they exist
  const removeClustersExpr = ["!", ["has", "point_count"]];
  allFilterExpr.push(removeClustersExpr);

  // create the primary filter for the layer
  if (activeLabel === "Platform") {
    allFilterExpr.push(["==", ["get", "platform"], layer]);
  } else if (activeLabel === "Season") {
    allFilterExpr.push(["in", layer, ["get", "categories"]]);
  } else if (activeLabel === "Type") {
    allFilterExpr.push(["==", ["get", "icon"], layer]);
  }

  // filter out the active marker
  // https://docs.mapbox.com/style-spec/reference/expressions/#any
  if (activeMarkerPlatform && activeMarkerID) {
    if (activeMarkerStartTime !== undefined) {
      allFilterExpr.push([
        "any",
        ["!=", ["get", "platform"], activeMarkerPlatform],
        ["!=", ["get", "id"], activeMarkerID],
        ["!=", ["get", "timeStart"], activeMarkerStartTime],
      ]);
    } else {
      allFilterExpr.push([
        "any",
        ["!=", ["get", "platform"], activeMarkerPlatform],
        ["!=", ["get", "id"], activeMarkerID],
      ]);
    }
  }

  // Create filter expressions for filter categories
  // multiple categories are combined with logical OR
  if (categories && categories.length > 0) {
    const categoryFilterExpr = [
      "any",
      ...categories.map((category) => ["in", category, ["get", "categories"]]),
    ];
    allFilterExpr.push(categoryFilterExpr);
  }

  // Chain the expressions with logical AND
  const finalFilterExpr = ["all", ...allFilterExpr];
  return finalFilterExpr;
}

const initialLayerState = (
  id: string,
  iconImage: string,
  filter: boolean | any,
  activeLabel: string
): LayerProps => ({
  id,
  source: "scenes",
  filter,
  paint: {
    "icon-opacity": activeLabel === "Platform" ? 0.6 : 1.0,
  },
  type: "symbol",
  layout: {
    "icon-image": iconImage,
    "icon-allow-overlap": false,
    "icon-padding": 0,
  },
});

const activeMarkerState = (
  id: string,
  platform: string,
  postID: string,
  startTime?: number
): LayerProps => ({
  id,
  source: "scenes",
  filter: [
    "all",
    ["==", ["get", "platform"], platform],
    ["==", ["get", "id"], postID],
    ...(startTime !== undefined
      ? [["==", ["get", "timeStart"], startTime]]
      : [true]),
  ],
  paint: {
    "icon-opacity": 1.0,
  },
  type: "symbol",
  layout: {
    "icon-image": "PioneerMapsIcon",
    "icon-allow-overlap": false,
    "icon-padding": 0,
  },
});

const getIconMap = (activeLabel: string) => {
  if (activeLabel === "Platform") {
    return PlatformIcons;
  } else if (activeLabel === "Season") {
    return SeasonIcons;
  } else if (activeLabel === "Type") {
    return TypeIcons;
  }
  return new Map(); // Default empty map if no match
};

// MapVisitSwitzerland component props interface
export interface MapVisitSwitzerlandProps {
  mapboxApiAccessToken: string;
  mapStyle: string;
  setDrawerActive: Function;
  drawerOpen: boolean;
  setDrawerOpen: Function;
  marker: IMarkerState | undefined;
  setMarker: Function;
  urlParams: any;
  playedSeconds: number;
}

export const MapVisitSwitzerland: React.FC<MapVisitSwitzerlandProps> = ({
  mapboxApiAccessToken,
  mapStyle,
  setDrawerActive,
  drawerOpen,
  setDrawerOpen,
  marker,
  setMarker,
  urlParams,
  playedSeconds,
}) => {
  const apiService = useApiService();

  const [viewport, setViewport] = useState({
    latitude: 46.7985286,
    longitude: 8.2317948,
    zoom: 5.7,
  });

  const [data, setData] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const handleFilterModalOpen = () => {
    sendEvent(
      "User",
      "filters clicked",
      `${tenantConfig.tenant} - ${tenantConfig.map}`
    );
    setFilterModalOpen(true);
  };
  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  };

  // map of available categories and their counts
  const [categoryCounts, setCategoryCounts] = React.useState({});

  const [filterCategories, setFilterCategories] = React.useState<string[]>([]);
  const [lastCMcall, setLastCMcall] = React.useState<number>(0);
  const [video, setVideo] = React.useState<IGetVideoResponse | undefined>(
    undefined
  );
  const [scene, setScene] = React.useState<IScene | undefined>(undefined);
  const [activeLabel, setActiveLabel] = useState("Platform");

  const { showAlert } = useAlert();

  const { isAuthenticated } = useAuth0();

  const { tenantConfig } = useTenantMap();

  const mapRef = useRef(null);

  const [layers, setLayers] = useState<string[]>([]);
  const [layerStates, setLayerStates] = useState<Record<string, LayerProps>>(
    {}
  );

  useEffect(() => {
    const iconMap = getIconMap(activeLabel);
    const newLayers = Array.from(iconMap.keys());

    const newLayerStates = newLayers.reduce((layerStates, layer) => {
      // when label platform is active, show the layer based on the platform that matches the layer
      // otherwise show the layers based on the categories that match the layer

      layerStates[layer] = initialLayerState(
        layer,
        layer,
        generateFilterExpr(
          layer,
          filterCategories,
          activeLabel,
          marker?.type,
          marker?.id,
          marker?.startTime
        ),
        activeLabel
      );
      return layerStates;
    }, {} as Record<string, LayerProps>);

    if (marker) {
      newLayerStates["activeMarker"] = activeMarkerState(
        "activeMarker",
        marker.type,
        marker.id,
        marker?.startTime
      );
      newLayers.push("activeMarker");
    }

    setLayers(newLayers);
    setLayerStates(newLayerStates);
  }, [filterCategories, activeLabel, marker]);

  useEffect(() => {
    if (mapRef.current === null) return;
    // @ts-ignore
    const map = mapRef.current.getMap();

    // load all custom icons initially
    map.on("load", function () {
      PlatformIcons.forEach((src, name) => {
        const customIcon = new Image(30, 30);
        customIcon.onload = () => {
          if (!map.hasImage(name)) {
            map.addImage(name, customIcon);
          }
        };
        customIcon.src = "/" + src;
      });

      SeasonIcons.forEach((src, name) => {
        const customIcon = new Image(30, 30);
        customIcon.onload = () => {
          if (!map.hasImage(name)) {
            map.addImage(name, customIcon);
          }
        };
        customIcon.src = "/" + src;
      });

      TypeIcons.forEach((src, name) => {
        const customIcon = new Image(30, 30);
        customIcon.onload = () => {
          if (!map.hasImage(name)) {
            map.addImage(name, customIcon);
          }
        };
        customIcon.src = "/" + src;
      });

      const customIcon = new Image(40, 40);
      customIcon.onload = () => {
        if (!map.hasImage("PioneerMapsIcon")) {
          map.addImage("PioneerMapsIcon", customIcon);
        }
      };
      customIcon.src = "/PioneerMapsIcon.svg";
    });
  }, [mapRef.current]);

  useEffect(() => {
    if (playedSeconds > 0) {
      // find matching video scene
      let newCurrentScene = video.scenes.find(
        (scene) =>
          scene.time_start <= playedSeconds && playedSeconds <= scene.time_end
      );
      // only update scene when it is new. when it has a different location. when
      // there is no location anymore also update everything but the location
      // (there we keep the old one to not break stuff)
      if (
        newCurrentScene &&
        newCurrentScene !== scene &&
        ((scene.location && !newCurrentScene.location) ||
          (newCurrentScene.location &&
            newCurrentScene.location?.lat !== scene.location?.lat))
      ) {
        setScene(newCurrentScene);
        const destinations = newCurrentScene.destinations
          ? newCurrentScene.destinations
          : [""];
        const categories = newCurrentScene.categories
          ? newCurrentScene.categories
          : [""];
        onSceneChange({
          lat: newCurrentScene.location
            ? newCurrentScene.location?.lat
            : scene.location?.lat,
          lng: newCurrentScene.location
            ? newCurrentScene.location?.lng
            : scene.location?.lng,
          type: urlParams.type,
          id: urlParams.id,
          // don't update startTime as video player is already playing
          startTime: parseFloat(urlParams.startTime),
          localInfoURL: newCurrentScene.localInfoURL,
          address: newCurrentScene.address,
          text: newCurrentScene.text,
          categories: categories,
          destination: destinations?.join(", "),
        });
        setViewport({
          ...viewport,
          zoom: 16,
          latitude: newCurrentScene.location
            ? newCurrentScene.location?.lat
            : scene.location?.lat,
          longitude: newCurrentScene.location
            ? newCurrentScene.location?.lng
            : scene.location?.lng,
        });
      }
    }
  }, [playedSeconds]);

  useEffect(() => {
    if (tenantConfig) {
      let video;

      if (urlParams.type && urlParams.id) {
        if (
          (urlParams.type == "instagram" || urlParams.type == "tiktok") &&
          !urlParams.startTime
        ) {
          apiService
            .getPhoto(
              urlParams.type,
              urlParams.id,
              tenantConfig.tenant,
              tenantConfig.map
            )
            .then((response: IGetPhotoResponse) => {
              // TODO setMarkerState for InstaPhoto
              // TODO cache api response in a smart way
              //   only do API call when response not already available
              //   with cloudfront caching in place as well this is only
              //   required to reduce cloudfront costs not for api/k8s
              //   optimization
              onMarkerSelect({
                lat: response.lat,
                lng: response.lng,
                type: urlParams.type,
                thumbnailURL: response.imageURL,
                id: urlParams.id,
                userPhoto: response.photographer,
                userPost: response.authorName,
                address: response.address,
                text: response.text,
                localInfoURL: response.localInfoURL,
                categories: response.categories,
                destination: response.destinations?.join(", "),
              });
              const destinations = response.destinations
                ? response.destinations
                : [""];
              const categories = response.categories
                ? response.categories
                : [""];
              setViewport({
                ...viewport,
                zoom: 16,
                latitude: response.lat,
                longitude: response.lng,
              });
            })
            .catch((e: Error) => {
              console.log(e);
              showAlert("Oops! Something went wrong.", "error", 5);
            });
        } else if (urlParams.type && urlParams.id && urlParams.startTime) {
          // check if video
          apiService
            .getVideo(
              urlParams.type,
              urlParams.id,
              tenantConfig.tenant,
              tenantConfig.map
            )
            .then((response: IGetVideoResponse) => {
              setVideo(response);
              const startTime = parseFloat(urlParams.startTime);
              if (response.scenes) {
                const filteredScenes = response.scenes.filter(
                  (scene) =>
                    scene.time_start <= startTime &&
                    (!scene.time_end || startTime <= scene.time_end)
                );

                const sceneOfSpot = filteredScenes.sort(
                  (a, b) => b.time_start - a.time_start
                )[0];

                if (sceneOfSpot) {
                  setScene(sceneOfSpot);
                  const destinations = sceneOfSpot.destinations
                    ? sceneOfSpot.destinations
                    : [""];
                  const categories = sceneOfSpot.categories
                    ? sceneOfSpot.categories
                    : [""];
                  onMarkerSelect({
                    lat: sceneOfSpot.location.lat,
                    lng: sceneOfSpot.location.lng,
                    type: urlParams.type,
                    id: urlParams.id,
                    startTime: startTime,
                    localInfoURL: sceneOfSpot.localInfoURL,
                    address: sceneOfSpot.address,
                    text: sceneOfSpot.text,
                    categories: categories,
                    destination: destinations?.join(", "),
                  });
                  setViewport({
                    ...viewport,
                    zoom: 16,
                    latitude: sceneOfSpot.location.lat,
                    longitude: sceneOfSpot.location.lng,
                  });
                } else {
                  console.log("No matching scene found");
                  showAlert("Oops! Something went wrong.", "error", 5);
                }
              }
            })
            .catch((e: Error) => {
              console.log(e);
              showAlert("Oops! Something went wrong.", "error", 5);
            });
        }
      }
    }
  }, [history.location, tenantConfig]);

  function prepareInstaPhoto(platformName: string, id: string) {
    let newURLParams = urlParams;
    delete newURLParams.startTime;
    history.push({
      search: new URLSearchParams({
        ...newURLParams,
        type: platformName,
        id: id,
      }).toString(),
    });
  }

  function prepareVideo(platformName: string, id: string, startTime: number) {
    history.push({
      search: new URLSearchParams({
        ...urlParams,
        type: platformName,
        id: id,
        startTime: startTime.toString(),
      }).toString(),
    });
  }

  function processData(responseData) {
    // Set the data state with the raw GeoJSON data
    setData(responseData);

    // Extract and count categories from the GeoJSON data
    const categoryCounts = extractAndCountCategories(responseData);

    setCategoryCounts(categoryCounts);
  }

  function extractAndCountCategories(data) {
    const counts = {};
    data.features.forEach((feature) => {
      const categories = feature.properties.categories;
      if (categories) {
        categories.forEach((category) => {
          counts[category] = counts.hasOwnProperty(category)
            ? counts[category] + 1
            : 1;
        });
      }
    });

    return counts;
  }

  function onMarkerSelect(markerState) {
    if (markerState.startTime) {
      sendEvent(
        "User",
        "map marker click",
        "type=" +
          markerState.type +
          "&id=" +
          markerState.id +
          "&startTime=" +
          markerState.startTime
      );
    } else {
      sendEvent(
        "User",
        "map marker click",
        "type=" + markerState.type + "&id=" + markerState.id
      );
    }

    setDrawerOpen(!drawerOpen);
    setDrawerActive(true);
    // @ts-ignore
    setMarker(markerState);
  }

  function onSceneChange(markerState) {
    if (!drawerOpen) {
      setDrawerOpen(!drawerOpen);
    }
    setDrawerActive(true);
    // @ts-ignore
    setMarker(markerState);
  }

  function handleViewportChange(viewport: any) {
    if (data.features.length === 0 && !urlParams.ftype && !urlParams.fid) {
      if (Date.now() - lastCMcall > 1000) {
        setLastCMcall(Date.now());
        if (apiService) {
          apiService
            .getMap(tenantConfig.tenant, tenantConfig.map, !isAuthenticated)
            .then((response) => {
              processData(response); // Adjust processData as needed; response is already response based on your API service
            })
            .catch((error) => {
              console.log(error);
              showAlert("Oops! Something went wrong.", "error", 5);
            });
        } else {
          console.error("apiService not available");
          showAlert("Oops! Something went wrong.", "error", 5);
        }
      }
    }

    setViewport(viewport);
  }

  const onClick = (event: any) => {
    if (event.features.length === 0) {
      // click on map. not on a marker
      let newURLParams = urlParams;
      delete newURLParams.type;
      delete newURLParams.id;
      delete newURLParams.startTime;
      history.push({
        search: new URLSearchParams({
          ...newURLParams,
        }).toString(),
      });
      setDrawerActive(false);
      setMarker(undefined);
      return;
    }
    const feature = event.features[0];

    if (feature.properties.hasOwnProperty("cluster_id")) {
      const clusterId = feature.properties.cluster_id;

      // @ts-ignore
      const mapboxSource = mapRef.current.getMap().getSource("scenes");

      mapboxSource.getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
        if (err) {
          return;
        }

        setViewport({
          ...viewport,
          longitude: feature.geometry.coordinates[0],
          latitude: feature.geometry.coordinates[1],
          zoom,
          // @ts-ignore
          transitionDuration: 500,
        });
      });
    } else {
      if ("timeStart" in feature.properties) {
        prepareVideo(
          feature.properties.platform,
          feature.properties.id,
          feature.properties.timeStart
        );
      } else {
        prepareInstaPhoto(feature.properties.platform, feature.properties.id);
      }
    }
  };

  // @ts-ignore
  return (
    <div className={"flex"}>
      <ReactMapGl
        {...viewport}
        width="100%"
        height="100%"
        mapboxApiAccessToken={mapboxApiAccessToken}
        mapStyle={mapStyle}
        onViewportChange={(viewport: any) => handleViewportChange(viewport)}
        interactiveLayerIds={layers.map((layer) => layerStates[layer].id)}
        onClick={onClick}
        ref={mapRef}
      >
        <div className={"filter-button"}>
          <FilterButton
            onClick={() => handleFilterModalOpen()}
            disabled={Object.keys(categoryCounts).length === 0}
            selectCount={filterCategories.length}
          />
        </div>
        <GeolocateControl className={"locate-button"} />
        {/* @ts-ignore */}
        <Source
          id="scenes"
          type="geojson"
          // @ts-ignore
          data={data}
          // cluster* params don't have any effect when the clusters are
          // generated server-side, instead play with params in backend
          // code, see clustermap/index.ts
          // cluster={true}
          // clusterMaxZoom={10}
          // @ts-ignore
          // clusterMinPoints={20}
          // clusterRadius={80}
        >
          {/* @ts-ignore */}
          {layers.map((layer) => (
            // @ts-ignore
            <Layer key={layer} {...layerStates[layer]} />
          ))}
          {marker && (
            <Layer
              {...activeMarkerState(
                "activeMarker",
                marker.type,
                marker.id,
                marker?.startTime
              )}
            />
          )}

          {/* @ts-ignore */}
          {/* clusterCount does not work when mapStyle is */}
          {/* the swisstopo map.*/}
          {/* with mapbox maps it works. No clue why*/}
          {/* <Layer {...clusterCountLayer} />*/}
        </Source>
      </ReactMapGl>

      <div className="label-container">
        <LabelPlatform
          active={activeLabel === "Platform"}
          onClick={() => setActiveLabel("Platform")}
        >
          Platform
        </LabelPlatform>
        <LabelSeason
          active={activeLabel === "Season"}
          onClick={() => setActiveLabel("Season")}
        >
          Season
        </LabelSeason>
        <LabelType
          active={activeLabel === "Type"}
          onClick={() => setActiveLabel("Type")}
        >
          Type
        </LabelType>
      </div>

      <FilterForm
        title={
          tenantConfig
            ? tenantConfig.creator + " Exclusive Filters"
            : "Exclusive Filters"
        }
        filterModalOpen={filterModalOpen}
        handleFilterModalClose={handleFilterModalClose}
        onCloseButton={handleFilterModalClose}
        selectedCategories={filterCategories}
        setSelectedCategories={setFilterCategories}
        categoryCounts={categoryCounts}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    urlParams: state.router.location.query,
  };
};

export default connect(mapStateToProps)(MapVisitSwitzerland);
